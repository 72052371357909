export const updateAttorney = /* GraphQL */ `
mutation UpdateAttorney(
  $input: UpdateAttorneyInput!
  $condition: ModelAttorneyConditionInput
) {
  updateAttorney(input: $input, condition: $condition) {
    id
    firstName
    lastName
    approved
  }
}
`;
