import React from 'react';
import './App.css';
import AttorneyList from './components/AttorneyList'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import Amplify from "aws-amplify";
import AWSConfig from './aws-exports-test.js'
Amplify.configure(AWSConfig);


function App() {
  return (
    <div className="App">
      <div style={{margin: '0 auto', display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <AttorneyList></AttorneyList>
        <div style={{marginTop: 'auto'}}>
          <AmplifySignOut />
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(App);
