import React, { useEffect, useState } from 'react';
import * as queries from '../graphql/customQueries';
import { updateAttorney } from '../graphql/customMutations';
import { API, graphqlOperation } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

const columns = [
  { field: 'id', headerName: 'Id'},
  { field: 'firstName', headerName: 'First name'},
  { field: 'lastName', headerName: 'Last name'},
  { field: 'emailWork', headerName: 'Email Work'},
  { field: 'cellPhone', headerName: 'Phone Number'},
  { field: 'licenseNumber', headerName: 'License Number'},
  { field: 'firmAssociation', headerName: 'Firm Association'},
  { field: 'practiceAreas', headerName: 'Practice Areas'},
  { field: 'statesOfLicense', headerName: 'States'},

  { field: 'currentProfessionalResponsibilityInvestigations', headerName: 'PR Investigation'},
  { field: 'acceptsTCAndPP', headerName: 'Accepts TOC && PP'},

  { field: 'createdAt', headerName: 'Created At'},
  { field: 'updatedAt', headerName: 'Last Modified'},
  { field: 'action', headerName: 'Action'},
];



const lowerCase = str => str.toLowerCase();

const filterAttorneys = (attorneys, search) => {

  if(!search) return attorneys;

  const filtered = attorneys.filter(item => {
    const {firstName, lastName} = item;

    return (
      lowerCase(firstName).indexOf(search) !== -1 ||
      lowerCase(lastName).indexOf(search) !== -1
    )
  })

  return filtered;
}

const myFilter = {
  filter: {
    approved: {
      ne: true
    }
  },
  limit: 1000000
};

function AttorneyList() {
  const [attorneys, setAttorneys] = useState(null);
  const [search, setSearch] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
      (
        async () => {
          const response = await API.graphql(graphqlOperation(queries.listAttorneysForAdmin, myFilter));

          const attorneys = response.data.listAttorneys.items.filter(item => item.approved === null);
          attorneys.sort((attorney1, attorney2) => {
            const name1 = attorney1.lastName+attorney1.firstName; 
            const name2 = attorney2.lastName+attorney2.firstName; 
            return name1.localeCompare(name2);
          });

          setAttorneys(attorneys);
        }
    )();
  }, []);


  async function approvalAction(attorney, index, approved) {
    try {
      setLoading(true);
      await API.graphql(graphqlOperation(updateAttorney, {
        input: {
          id: attorney.id,
          approved
        },
      }));
  
      attorney.approved = true;
      setAttorneys([...attorneys]);
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h1>
         Test Environment Unapproved Attorneys &nbsp;
        </h1>
        {
          isLoading && <CircularProgress color="secondary" />
        }
      </div>
      { 
        attorneys ?
          <>
            <TextField
              id="outlined-search"
              label="Search field"
              type="search"
              variant="outlined"
              style={{width: '100%', marginBottom: 20}}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div align="left" style={{fontSize: 12}}>Total: {attorneys ? attorneys.length : 0}</div>
            <div style={{height: 450}}>
              <TableContainer component={Paper} style={{height: '100%'}}>
                <Table >
                  <TableHead>
                    <TableRow>
                      {
                        columns.map((item) => (
                          <TableCell key={item.field}>
                            <b>{ item.headerName }</b>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterAttorneys(attorneys, search).map((item, i) => (
                      <TableRow key={i}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.firstName}</TableCell>
                        <TableCell>{item.lastName}</TableCell>
                        <TableCell>{item.emailWork}</TableCell>
                        <TableCell>{item.cellPhone}</TableCell>
                        <TableCell>{item.licenseNumber}</TableCell>
                        <TableCell>{item.firmAssociation}</TableCell>
                        <TableCell>
                          {
                            item.practiceAreas ?
                              item.practiceAreas.map((area, i) => (
                                <Chip label={area} key={i} />
                              )) :
                              'N/A'
                          }
                        </TableCell>
                        <TableCell>
                          {
                            item.statesOfLicense ?
                              item.statesOfLicense.map((area, i) => (
                                <Chip label={area} key={i} />
                              )) :
                              'N/A'
                          }
                        </TableCell>
                        <TableCell  style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word"
                    }}>{item.currentProfessionalResponsibilityInvestigations}</TableCell>
                        <TableCell>{item.acceptsTCAndPP ? 'Accepted' : 'Not Accepted'}</TableCell>

                        <TableCell
                        >{moment(item.createdAt).format('M[/]D[/]YYYY, h:mm a')}</TableCell>
                        <TableCell>{moment(item.updatedAt).format('M[/]D[/]YYYY, h:mm a')}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => approvalAction(item, i, true)}
                            disabled={item.approved !== null}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => approvalAction(item, i, false)}
                            disabled={item.approved !== null}
                          >
                            Deny
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>:
          "Fetching attorneys"
      }
    </div>
  );

}

export default AttorneyList;